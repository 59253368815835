<template>
  <ListItem>
    <BranchFragment
      class="m-mb-8"
      :branch="branchGetter(branch.id)"
    />
    <div class="m-stack-horizontal">
      <p>Počet návštev: <b>{{ branch.visits.length }}</b></p>
      <p>Skóre: <b>{{ formatScore(averageScore) }}</b></p>
    </div>
  </ListItem>
</template>

<script setup>
import { computed } from 'vue';
import formatScore from '@/util/formatScore';

import ListItem from '@/components/ListItem.vue';
import BranchFragment from '@/components/BranchFragment.vue';

const props = defineProps({
  branch: {
    type: Object,
    required: true,
  },
  branchGetter: {
    typer: Function,
    required: true,
  },
});

const averageScore = computed(() => {
  const average = (list) => list.reduce((a, b) => a + b, 0) / list.length;
  const scores = props.branch.visits.map((visit) => visit.score);
  return average(scores);
});
</script>
