import pluralize from './pluralize';

const activityCountWordForms = [
  'aktivita',
  'aktivity',
  'aktivít',
];

const formatActivityCount = (n) => pluralize(n, activityCountWordForms);

export default formatActivityCount;
