<template>
  <BranchesList
    :branches="showingClosestBranches ? closestBranches : lists.branches"
    :allowClosest="!showingClosestBranches"
    @select="selectBranch"
    @requestClosest="getClosest"
    @changeQuery="showingClosestBranches = false"
  />
  <NewVisitBottombar
    hasBack
  />
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import useListsStore from '@/stores/lists';
import useVisitStore from '@/stores/visit';
import useMetaStore from '@/stores/meta';
import { getBranches } from '@/api';

import BranchesList from '@/components/BranchesList.vue';
import NewVisitBottombar from '@/components/NewVisitBottombar.vue';

const router = useRouter();
const lists = useListsStore();
const visit = useVisitStore();
const meta = useMetaStore();
const showingClosestBranches = ref(false);
const closestBranches = ref(null);

const selectBranch = (branch) => {
  visit.branch = branch.id;
  router.push({ name: 'NewVisit/ActivitiesList' });
};

const getClosest = () => {
  const successCallback = async (geolocationPosition) => {
    const { coords } = geolocationPosition;
    const params = {
      lat: coords.latitude,
      lng: coords.longitude,
    };
    closestBranches.value = await getBranches(params);
    showingClosestBranches.value = true;
    meta.loading = false;
  };
  const errorCallback = () => {
    // eslint-disable-next-line no-alert
    alert('Nebolo možné získať vašu polohu');
    meta.loading = false;
  };
  meta.loading = true;
  navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
};
</script>
