<template>
  <div class="attachment-uploader">
    <label
      class="uploader-button"
      for="fileInput"
    >
      <BaseIcon
        :size="32"
        icon="camera"
      />
      <span class="uploader-button-label m-font-size-200 m-font-bold">
        Pridať foto
      </span>
      <input
        type="file"
        ref="fileInputElement"
        id="fileInput"
        accept=".jpg,.jpeg,.png,.heic,.heif"

        @change="handleNewFile"
      >
    </label>

    <ul
      v-if="attachments.length || isLoading"
      class="uploader-list m-reset-list"
    >
      <li
        v-for="(att, i) in attachments"
        :key="att.id"
      >
        <AttachmentPreview
          :attachment="att"
          @open="handler.open(att)"
          @remove="handler.remove(i)"
        />
      </li>
      <li v-if="isLoading">
        Nahrávam…
      </li>
      <li>
        <button
          class="m-reset-button"
          type="button"
          @click="handleAddMore"
        >
          + Pridať ďalšie
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup>
/* eslint-disable vue/no-mutating-props */
import { ref } from 'vue';
import { uploadAttachment } from '@/api';
import apiBase from '@/api/apiBase';

import BaseIcon from '@/components/BaseIcon.vue';
import AttachmentPreview from '@/components/AttachmentPreview.vue';

const props = defineProps({
  attachments: {
    type: Array,
    default: () => [],
  },
});

const fileInputElement = ref(null);
const isLoading = ref(false);

const handler = {
  add(file) {
    props.attachments.push({
      clientName: file.clientName,
      id: file.id,
    });
  },
  remove(i) {
    props.attachments.splice(i, 1);
  },
  open(att) {
    window.open(
      `${apiBase}/visit/attachment/${att.id}`,
      '_blank',
    );
  },
};

const handleNewFile = async () => {
  isLoading.value = true;
  const file = fileInputElement.value.files[0];
  const id = await uploadAttachment(file);
  handler.add({
    clientName: file.name,
    id,
  });
  fileInputElement.value.value = '';
  isLoading.value = false;
};

const handleAddMore = () => {
  fileInputElement.value.click();
};

</script>

<style scoped>
.attachment-uploader {
  border: var(--simple-border);
  padding: 16px;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.uploader-button {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.uploader-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
  border-top: var(--simple-border);
}
</style>
