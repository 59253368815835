const pluralize = (number, forms) => {
  switch (number) {
    case 1:
      return `${number} ${forms[0]}`;
    case 2:
      return `${number} ${forms[1]}`;
    case 3:
      return `${number} ${forms[1]}`;
    case 4:
      return `${number} ${forms[1]}`;
    default:
      return `${number} ${forms[2]}`;
  }
};

export default pluralize;
