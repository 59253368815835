<template>
  <div>
    <ElevatedHeader>
      <div class="m-stack-vertical">
        <BranchFragment
          :branch="lists.getBranch(visit?.branch)"
        />
        <div class="m-stack-horizontal">
          <p>
            Dátum: <b>{{ formatDate(visit?.performedAt) }}</b>
          </p>
          <p>
            Skóre: <b>{{ formatScore(visit?.score) }}</b>
          </p>
        </div>
        <p class="m-font-size-100">
          Pre metodiku výpočtu skóre pozrite knižnicu dokumentov.
        </p>
        <BaseButton
          size="small"
          variant="ghost"
          icon="download"
          @click="downloadExport()"
        >
          Export štatistík
        </BaseButton>
      </div>
    </ElevatedHeader>
    <div
      v-if="visit"
      class="m-container m-pt-24"
    >
      <ActivityCards
        :activityTypes="lists.activityTypes"
        :activities="visit.activities"
        :employeeGetter="lists.getEmployee"
        @openActivity="$router.push({
          name: 'Stats/Detail/Activity',
          params: {
            visitId: id,
            activityId: $event,
          },
        })"
      />
      <div class="m-stack-horizontal -center">
        <BaseButton
          variant="link"
          size="small"
          @click="editMe()"
        >
          Upraviť návštevu
        </BaseButton>
        <BaseButton
          variant="link-danger"
          size="small"
          @click="deleteMe()"
        >
          Odstrániť
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch } from 'vue';
import { useRouter } from 'vue-router';
import useMetaStore from '@/stores/meta';
import useListsStore from '@/stores/lists';
import useVisitStore from '@/stores/visit';
import { getVisit, deleteVisit } from '@/api';
import formatDate from '@/util/formatDate';
import formatScore from '@/util/formatScore';
import apiBase from '@/api/apiBase';

import ElevatedHeader from '@/components/ElevatedHeader.vue';
import BranchFragment from '@/components/BranchFragment.vue';
import BaseButton from '@/components/BaseButton.vue';
import ActivityCards from '@/components/ActivityCards.vue';

const router = useRouter();
const meta = useMetaStore();
const lists = useListsStore();
const visit = useVisitStore();

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
});

const loadData = async () => {
  const loadedVisit = await getVisit(props.id);
  visit.setVisit(loadedVisit);
};

const downloadExport = () => {
  const url = `${apiBase}statistics/export-visit/${props.id}`;
  window.open(url);
};

const deleteMe = async () => {
  meta.loading = true;
  deleteVisit(props.id);
  meta.loading = false;
  router.push({ name: 'Stats/List/Branches' });
};

const editMe = () => {
  router.push({ name: 'NewVisit/ActivitiesList' });
};

watch(
  () => props.id,
  () => {
    loadData();
  },
  { immediate: true },
);

</script>
