<template>
  <div>
    <header
      class="employees-header"
      :class="{
        'm-pt-40': showPageHeader,
      }"
    >
      <div class="m-container">
        <PageHeader
          v-if="showPageHeader"
          icon="exclamationCircle"
          title="Vyberte zamestnanca"
        />
        <InputText
          placeholder="Hľadať podľa mena"
          v-model="query"
          @click="showPageHeader = false"
        />
      </div>
    </header>
    <div class="m-container">
      <ul class="m-reset-list" v-if="employeesFiltered.length">
        <li
          v-for="(employee, i) in employeesFiltered"
          :key="i"
        >
          <ListItemEmployee
            :employee="employee"
            @click="submitFromList(employee.id)"
          />
        </li>
      </ul>
      <div v-else class="m-stack-vertical -center m-pt-24">
        <h2 class="m-color-blue m-mb-16">Zamestnanec nebol nájdený</h2>
        <BaseButton
          v-if="showOnlyRelevant"
          variant="primary"
          @click="searchAll()"
        >
          Hľadať v celej databáze
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import normalizeString from '@/util/normalizeString';
import useListsStore from '@/stores/lists';
import useVisitStore from '@/stores/visit';

import PageHeader from '@/components/PageHeader.vue';
import InputText from '@/components/InputText.vue';
import ListItemEmployee from '@/components/ListItemEmployee.vue';
import BaseButton from '@/components/BaseButton.vue';

const props = defineProps({
  activityType: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['select']);

const lists = useListsStore();
const visit = useVisitStore();

const showPageHeader = ref(true);
const query = ref('');
const showOnlyRelevant = ref(true);

const employeesRelevant = computed(() => {
  if (!showOnlyRelevant.value) {
    return lists.employees;
  }
  return lists.employees
    .filter((employee) => employee.branchId === visit.branch
      || (employee.branch && employee.branch.id === visit.branch))
    .filter((employee) => employee.suitableFor.includes(props.activityType));
});

const employeesFiltered = computed(() => {
  if (!query.value) {
    return employeesRelevant.value;
  }
  const queryNormalized = normalizeString(query.value);
  // eslint-disable-next-line arrow-body-style
  const filtered = employeesRelevant.value.filter((employee) => {
    return normalizeString(employee.name).includes(queryNormalized)
      || normalizeString(employee.number).includes(queryNormalized);
  });
  return filtered;
});

const searchAll = () => {
  showOnlyRelevant.value = false;
};

const submitFromList = (id) => {
  emit('select', { id });
};

</script>

<style scoped>
.employees-header {
  padding-bottom: 16px;
  border-bottom: var(--simple-border);
  &:not(.m-pt-40) {
    padding-top: 16px;
  }
}
</style>
