const normalizeEmail = (email) => {
  if (typeof email !== 'string') {
    return '';
  }
  return email
    .trim()
    .toLowerCase();
};

export default normalizeEmail;
