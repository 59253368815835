<template>
  <div>
    <ElevatedHeader>
      <div class="m-stack-vertical">
        <EmployeeFragment
          v-if="activity"
          :employee="lists.getEmployee(activity.employee)"
          showNumber
        />
        <p>
          Skóre: <b>{{ formatScore(activity.score) }}</b>
        </p>
        <p class="m-font-size-100">
          Pre metodiku výpočtu skóre pozrite knižnicu dokumentov.
        </p>
      </div>
    </ElevatedHeader>
    <div
      v-if=activity
      class="m-container m-pt-24"
    >
      <div
        v-for="screen in activity.screens"
        :key="screen.label"
        class="activity-section"
      >
        <h3 class="activity-section-title">{{ screen.label }}</h3>
        <ul class="activity-section-answers m-reset-list">
          <li
            v-for="(item, i) in screen.questions"
            class="activity-answer"
            :key="i"
          >
            <div class="activity-answer-question">
              {{ item.question }}
            </div>
            <div class="activity-answer-answer">
              <AnswerBadge
                :value="item.answer"
              />
            </div>
          </li>
        </ul>
      </div>
      <h3 class="activity-section-title">Popis dohody</h3>
      <p>
        {{ activity.agreement.description }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import useListsStore from '@/stores/lists';
import useVisitStore from '@/stores/visit';
import formatScore from '@/util/formatScore';

import ElevatedHeader from '@/components/ElevatedHeader.vue';
import EmployeeFragment from '@/components/EmployeeFragment.vue';
import AnswerBadge from '@/components/AnswerBadge.vue';

const lists = useListsStore();
const visit = useVisitStore();

const props = defineProps({
  visitId: {
    type: String,
    required: true,
  },
  activityId: {
    type: String,
    required: true,
  },
});

const activity = computed(() => {
  const activityIndexNumber = Number(props.activityId);
  return visit.activities[activityIndexNumber];
});

</script>

<style scoped>
.activity-section {
  margin-bottom: 40px;
}

.activity-section-title {
  color: var(--blue);
  padding: 16px 0;
  border-bottom: var(--simple-border);
  margin-bottom: 16px;
  font-size: var(--font-size-300);
}

.activity-section-answers {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: var(--font-size-100);
}

.activity-answer {
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--blue);
}

.activity-answer-question {
  flex: 1;
}
</style>
