<template>
  <EmployeesView
    v-if="subscreen === 'EMPLOYEES'"
    @select="handleEmployeeSelect"
    :activityType="currentActivity.type"
  />

  <IntroView
    v-if="subscreen === 'INTRO'"
  />

  <QuestionsView
    v-if="subscreen === 'QUESTIONS'"
    :questionScreen="questionScreen"
  />

  <RatingView
    v-if="subscreen === 'RATING'"
    :activity="currentActivity"
  />

  <AgreementView
    v-if="subscreen === 'AGREEMENT'"
    :activity="currentActivity"
  />

  <NewVisitBottombar
    hasBack
    :hasContinue="subscreen !== 'EMPLOYEES'"
    @continue="proceed()"
  />

  <BaseButton
    v-if="subscreen === 'QUESTIONS'"
    class="notes-button"
    variant="ghost"
    size="small"
    @click="notesModalOpen = !notesModalOpen"
  >
    {{ notesModalOpen ? 'Zatvoriť poznámku' : 'Otvoriť poznámku' }}
  </BaseButton>

  <NotesModal
    :activity="currentActivity"
    v-if="notesModalOpen"
    @close="notesModalOpen = false"
  />
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import useVisitStore from '@/stores/visit';

import NewVisitBottombar from '@/components/NewVisitBottombar.vue';
import BaseButton from '@/components/BaseButton.vue';

import EmployeesView from '@/views/NewVisit/Activity/EmployeesView.vue';
import IntroView from '@/views/NewVisit/Activity/IntroView.vue';
import QuestionsView from '@/views/NewVisit/Activity/QuestionsView.vue';
import RatingView from '@/views/NewVisit/Activity/RatingView.vue';
import AgreementView from '@/views/NewVisit/Activity/AgreementView.vue';
import NotesModal from '@/views/NewVisit/Activity/NotesModal.vue';

const visit = useVisitStore();
const router = useRouter();

const props = defineProps({
  activityIndex: {
    type: String,
    default: null,
  },
  step: {
    type: String,
    default: null,
  },
});

const notesModalOpen = ref(false);
const activityIndexNumber = computed(() => Number(props.activityIndex) - 1);
const currentActivity = computed(() => visit.activities[activityIndexNumber.value]);
const stepNumber = computed(() => Number(props.step));

const totalStepCount = computed(() => currentActivity.value.screens.length + 4);

const subscreen = computed(() => {
  if (stepNumber.value === 1) {
    return 'EMPLOYEES';
  }
  if (stepNumber.value === 2) {
    return 'INTRO';
  }
  if (stepNumber.value === totalStepCount.value - 1) {
    return 'RATING';
  }
  if (stepNumber.value === totalStepCount.value) {
    return 'AGREEMENT';
  }
  return 'QUESTIONS';
});

const questionsIndex = computed(() => stepNumber.value - 3);
const questionScreen = computed(() => {
  if (!currentActivity.value) {
    return {};
  }
  return currentActivity.value.screens[questionsIndex.value];
});

const proceed = (useReplace) => {
  if (subscreen.value === 'QUESTIONS') {
    const hasEmptyAnswers = questionScreen.value.answers
      .some((item) => item.answer === null);
    if (hasEmptyAnswers && currentActivity.value?.type !== 'branch-inspection') {
      // eslint-disable-next-line no-alert
      alert('Zodpovedzte všetky otázky');
      return false;
    }
  }

  if (stepNumber.value === totalStepCount.value) {
    router.push({ name: 'NewVisit/ActivitiesList' });
  } else {
    const to = {
      name: 'NewVisit/Activity',
      params: {
        activityIndex: activityIndexNumber.value + 1,
        step: stepNumber.value + 1,
      },
    };
    if (useReplace) {
      router.replace(to);
    } else {
      router.push(to);
    }
  }
  return true;
};

const handleEmployeeSelect = (employeeId) => {
  currentActivity.value.employee = employeeId;
  const useReplace = employeeId === null;
  proceed(useReplace);
};

onMounted(() => {
  if (currentActivity.value?.type === 'branch-inspection'
    && props.step === '1') {
    router.replace({
      name: 'NewVisit/Activity',
      params: {
        activityIndex: props.activityIndex,
        step: 2,
      },
    });
  }
});

</script>

<style scoped>
.notes-button {
  position: fixed;
  top: 12px;
  right: 12px;
  background-color: var(--yellow);
}
</style>
