<template>
  <div
    class="monthly-progress"
    :class="{
      '-done': current >= desired,
    }"
  >
    <div class="m-container monthly-progress-main">
      <span class="monthly-progress-top">
        Tento mesiac ste absolvovali
      </span>
      <span class="monthly-progress-value">
        {{ pluralize(current, visitWordForms) }}
      </span>
      <span class="monthly-progress-mini">
        {{ current }} / {{ desired }}
      </span>
    </div>
    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
    <progress
      class="monthly-progress-bar"
      :value="current"
      :max="desired"
    />
  </div>
</template>

<script setup>
import pluralize from '@/util/pluralize';

defineProps({
  current: {
    type: Number,
    default: 0,
  },
  desired: {
    type: Number,
    default: 0,
  },
});

const visitWordForms = ['návštevu', 'návštevy', 'návštev'];

</script>

<style scoped>
.monthly-progress {
  background-color: var(--yellow);
  color: var(--gray-900);
  border-top: 1px solid var(--yellow-dark);
  &.-done {
    background-color: var(--green);
    color: var(--white);
  }
}

.monthly-progress-main {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 16px;
  padding-bottom: 20px;
  position: relative;
}

.monthly-progress-top {
  font-size: var(--font-size-300);
}

.monthly-progress-value {
  font-size: 32px;
  font-weight: 700;
}

.monthly-progress-mini {
  position: absolute;
  right: 16px;
  bottom: 8px;
  font-size: var(--font-size-100);
  opacity: 0.75;
}

.monthly-progress-bar {
  --progress-background: var(--yellow-dark);
  --progress-color: var(--blue);

  appearance: none;
  border: 0;

  display: block;
  height: 5px;
  width: 100%;
  background-color: var(--progress-background);
}

.monthly-progress-bar[value]::-webkit-progress-bar {
  background-color: var(--progress-background);
}
.monthly-progress-bar[value]::-webkit-progress-value {
  background-color: var(--progress-color);
}
.monthly-progress-bar[value]::-moz-progress-bar {
  background-color: var(--progress-color);
}
</style>
