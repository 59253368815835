<template>
  <div class="m-center-screen m-container">
    <PageHeader
      v-if="!isSent"
      icon="verified"
      iconSize="large"
      title="Zabudnuté heslo"
      lead="Zadajte vašu emailovú adresu. Pošleme tam odkaz, cez ktorý si budete môcť zmeniť heslo."
    />
    <FormSheet
      v-if="!isSent"
      @submit="submit"
    >
      <FormGroup
        label="Emailová adresa"
      >
        <InputText
          type="email"
          required
          v-model="email"
        />
      </FormGroup>
      <FormError v-if="isError">
        Chyba, skontrolujte zadanú adresu
      </FormError>
      <BaseButton
        type="submit"
      >
        Odoslať
      </BaseButton>
      <BaseButton
        type="submit"
        variant="tertiary"
        @click="$router.back()"
      >
        Späť
      </BaseButton>
    </FormSheet>
    <PageHeader
      v-if="isSent"
      icon="checkCircle"
      iconSize="large"
      title="Žiadosť zaznamenaná"
      lead="Ak je email, ktorý ste zadali, registrovaný v systéme, poslali sme na neho potvrdzovací email. Skontrolujte svoju schránku a pokračujete cez zaslaný link."
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { requestPasswordReset } from '@/api';
import useMetaStore from '@/stores/meta';
import normalizeEmail from '@/util/normalizeEmail';

import PageHeader from '@/components/PageHeader.vue';
import FormSheet from '@/components/FormSheet.vue';
import FormError from '@/components/FormError.vue';
import FormGroup from '@/components/FormGroup.vue';
import InputText from '@/components/InputText.vue';
import BaseButton from '@/components/BaseButton.vue';

const meta = useMetaStore();

const email = ref('');
const isSent = ref(false);
const isError = ref(false);

const submit = async () => {
  isError.value = false;
  if (!email.value) {
    isError.value = true;
    return;
  }
  meta.loading = true;
  await requestPasswordReset(normalizeEmail(email.value));
  isSent.value = true;
  meta.loading = false;
};

</script>
