<template>
  <div class="app">
    <AppHeader
      v-if="showHeader"
      :type="$route.meta.header"
    />
    <RouterView />
    <LoadingOverlay
      :active="meta.loading"
    />
    <DataLoader />
  </div>
</template>

<script setup>
import { onMounted, computed } from 'vue';
import { useRoute, RouterView } from 'vue-router';
import useMeStore from '@/stores/me';
import useMetaStore from '@/stores/meta';
import headerTypes from '@/data/headerTypes';

import AppHeader from '@/components/AppHeader.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import DataLoader from '@/components/DataLoader.vue';

const meta = useMetaStore();
const me = useMeStore();
const route = useRoute();

const showHeader = computed(() => {
  const allowList = [headerTypes.REGULAR, headerTypes.LOGO_ONLY, headerTypes.BACK];
  return allowList.includes(route.meta.header);
});

onMounted(() => {
  me.retrieveSession();
});

</script>
