<template>
  <div>
    <MonthlyProgress
      v-if="me.dashboardData.stats"
      :current="me.dashboardData.stats.current"
      :desired="me.dashboardData.stats.desired"
    />
    <div class="m-container m-pt-24 m-pb-24">
      <nav class="dashboard-navigation">
        <NavTile
          v-if="me.hasPermission(permissions.VISIT_CREATE)"
          label="Nová návšteva<br> na zlepšenie"
          icon="home"
          style="grid-column: span 4"
          primary
          :to="{ name: 'NewVisit/Date' }"
          @click="resetVisit()"
        />
        <NavTile
          v-if="me.hasPermission(permissions.VISIT_STATS_SELF)"
          label="Zoznam<br> návštev"
          icon="clipboard"
          style="grid-column: span 2"
          :to="{ name: 'Stats/List/Branches' }"
        />
        <NavTile
          v-if="me.hasPermission(permissions.VISIT_STATS_SUBORDINATES)
            || me.hasPermission(permissions.VISIT_STATS_ALL)"
          label="Štatistiky<br> podriadených"
          icon="userGroup"
          style="grid-column: span 3"
          :to="{ name: 'Stats/Filter/Author' }"
        />
        <NavTile
          v-if="me.hasPermission(permissions.DOCUMENTS_VIEW)"
          label="Knižnica<br> dokumentov"
          icon="folder"
          style="grid-column: span 3"
          :to="{ name: 'DocumentsList' }"
        />
      </nav>
      <section
        v-if="lists.documents.list.length"
        class="dashboard-documents"
      >
        <h2>Dokumenty</h2>
        <div class="dashboard-documents-grid">
          <DocumentTile
            v-for="(document, i) in lists.documents.list"
            :key="i"
            :document="document"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import useMeStore from '@/stores/me';
import useVisitStore from '@/stores/visit';
import useListsStore from '@/stores/lists';
import permissions from '@/data/permissions';

import MonthlyProgress from '@/components/MonthlyProgress.vue';
import NavTile from '@/components/NavTile.vue';
import DocumentTile from '@/components/DocumentTile.vue';

const me = useMeStore();
const lists = useListsStore();

const resetVisit = () => {
  const visit = useVisitStore();
  visit.reset();
};

</script>

<style scoped>
.dashboard-navigation {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr 1fr;
  gap: 16px;
}

.dashboard-documents {
  padding-top: 24px;
  & h2 {
    font-size: var(--font-size-400);
    margin-bottom: 16px;
  }
  & .dashboard-documents-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}
</style>
