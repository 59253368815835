<template>
  <div class="m-container m-center-screen">
    <PageHeader
      icon="lock"
      iconSize="large"
      title="Zmena hesla"
    />
    <FormSheet
      @submit="submit()"
    >
      <FormGroup
        label="Nové heslo"
      >
        <InputText
          type="password"
          v-model="newPassword"
        />
      </FormGroup>
      <FormGroup
        label="Nové heslo znova"
      >
        <InputText
          type="password"
          v-model="newPasswordRepeat"
        />
      </FormGroup>
      <FormError
        v-if="isMismatch"
      >
        Heslá sa nezhodujú
      </FormError>
      <FormError
        v-if="isError"
      >
        Nepodarilo sa zmeniť heslo
      </FormError>
      <BaseButton
        type="submit"
      >
        Zmeniť heslo
      </BaseButton>
    </FormSheet>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import useMetaStore from '@/stores/meta';
import { submitPasswordReset } from '@/api';

import PageHeader from '@/components/PageHeader.vue';
import FormSheet from '@/components/FormSheet.vue';
import FormGroup from '@/components/FormGroup.vue';
import FormError from '@/components/FormError.vue';
import InputText from '@/components/InputText.vue';
import BaseButton from '@/components/BaseButton.vue';

const props = defineProps({
  token: {
    type: String,
    default: '',
  },
});

const router = useRouter();
const meta = useMetaStore();

const isMismatch = ref(false);
const isError = ref(false);
const newPassword = ref('');
const newPasswordRepeat = ref('');

const resetErrors = () => {
  isMismatch.value = false;
  isError.value = false;
};

const submit = async () => {
  resetErrors();
  if (newPassword.value !== newPasswordRepeat.value) {
    isMismatch.value = true;
    return false;
  }
  meta.loading = true;
  try {
    const response = await submitPasswordReset({
      token: props.token,
      newPassword: newPassword.value,
    });
    if (response.success) {
      // eslint-disable-next-line no-alert
      alert('Heslo zmenené');
      router.push({ name: 'Dashboard' });
    } else {
      // eslint-disable-next-line no-alert
      alert(response.message);
    }
  } catch (e) {
    isError.value = true;
  } finally {
    meta.loading = false;
  }
  return true;
};

</script>
